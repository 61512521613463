import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchPlayers, fetchTeams, fetchTournaments, fetchReferees } from '../services/apiService';
import {
  TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Modal, Box, Typography, Button, IconButton, useMediaQuery, Tabs, Tab, Grid, Tooltip, MenuItem, Select
} from '@mui/material';
import { Add as AddIcon, People as PeopleIcon, Sports as SportsIcon, Group as GroupIcon, SportsSoccer as SportsSoccerIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const PlayerAdminPage = () => {
  const [players, setPlayers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [referees, setReferees] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [sortOrder, setSortOrder] = useState('asc'); // New state for sort order
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const loadData = async () => {
      const [fetchedPlayers, fetchedTeams, fetchedTournaments, fetchedReferees] = await Promise.all([
        fetchPlayers(), fetchTeams(), fetchTournaments(), fetchReferees()
      ]);
      setPlayers(fetchedPlayers);
      setTeams(fetchedTeams);
      setTournaments(fetchedTournaments);
      setReferees(fetchedReferees);
    };
    loadData();
  }, []);

  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  const handleSortOrderChange = (e) => setSortOrder(e.target.value);

  const filteredPlayers = players
    .filter(player =>
      [player.FirstName, player.LastName, player.Email].some(field =>
        field?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    )
    .sort((a, b) => sortOrder === 'asc' 
      ? new Date(a.createdAt) - new Date(b.createdAt)
      : new Date(b.createdAt) - new Date(a.createdAt)
    );

  const handleRowClick = (player) => {
    setSelectedPlayer(player);
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);
  const handleCreatePlayer = () => navigate('/create-player');
  const handleTabChange = (event, newTab) => setSelectedTab(newTab);

  return (
    <div style={{ padding: '10px', maxWidth: '100%', margin: '0 auto' }}>
      <Typography variant="h4" align="center" sx={{ color: '#3f51b5', marginBottom: 2 }}>
        Admin Dashboard
      </Typography>

      <Tabs value={selectedTab} onChange={handleTabChange} centered variant="fullWidth">
        <Tab icon={<PeopleIcon />} label="Players" />
        <Tab icon={<GroupIcon />} label="Teams" />
        <Tab icon={<SportsSoccerIcon />} label="Tournaments" />
        <Tab icon={<SportsIcon />} label="Referees" />
      </Tabs>

      {selectedTab === 0 && (
        <>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
            <Grid item xs={12} md={4}>
              <TextField
                label="Search Players"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchChange}
                fullWidth
                size="small"
                sx={{
                  backgroundColor: '#f1f1f1',
                  borderRadius: 1,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: '#3f51b5' },
                    '&:hover fieldset': { borderColor: '#1e88e5' },
                    '&.Mui-focused fieldset': { borderColor: '#4caf50' },
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Select
                label="Sort by Date"
                value={sortOrder}
                onChange={handleSortOrderChange}
                fullWidth
                displayEmpty
                size="small"
                sx={{ backgroundColor: '#f1f1f1', borderRadius: 1 }}
              >
                <MenuItem value="asc">Oldest to Newest</MenuItem>
                <MenuItem value="desc">Newest to Oldest</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6} md={4} sx={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleCreatePlayer}
                sx={{
                  backgroundColor: '#4caf50',
                  color: 'white',
                  '&:hover': { backgroundColor: '#388e3c' },
                  width: isMobile ? '100%' : 'auto',
                }}
              >
                Add New Player
              </Button>
            </Grid>
          </Grid>

          <TableContainer component={Paper} sx={{ maxHeight: 400, mt: 2 }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>First Name</TableCell>
                  <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Last Name</TableCell>
                  <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Email</TableCell>
                  <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Team</TableCell>
                  <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Active</TableCell>
                  <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredPlayers.map((player) => (
                  <TableRow key={player.PlayerId} hover onClick={() => handleRowClick(player)}>
                    <TableCell>{player.FirstName}</TableCell>
                    <TableCell>{player.LastName}</TableCell>
                    <TableCell>{player.Email}</TableCell>
                    <TableCell>{player.TeamId}</TableCell>
                    <TableCell>{player.Active ? 'Yes' : 'No'}</TableCell>
                    <TableCell>
  {new Date(player.createdAt).toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true // Use 12-hour format
  })}
</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

<Modal open={openModal} onClose={handleCloseModal}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: 1,
    }}
  >
    {selectedPlayer && (
      <>
        <Typography variant="h6" gutterBottom>
          {selectedPlayer.FirstName} {selectedPlayer.LastName}
        </Typography>
        <Typography variant="body1"><strong>Email:</strong> {selectedPlayer.Email}</Typography>
        <Typography variant="body1"><strong>Phone:</strong> {selectedPlayer.PhoneNumber}</Typography>
        <Typography variant="body1"><strong>Date of Birth:</strong> {selectedPlayer.DOB}</Typography>
        <Typography variant="body1"><strong>Position:</strong> {selectedPlayer.Position}</Typography>
        <Typography variant="body1"><strong>Age:</strong> {selectedPlayer.Age}</Typography>
        <Typography variant="body1"><strong>Active:</strong> {selectedPlayer.Active ? 'Yes' : 'No'}</Typography>
        <Typography variant="body1"><strong>Is Playing:</strong> {selectedPlayer.IsPlaying ? 'Yes' : 'No'}</Typography>
        <Typography variant="body1"><strong>League ID:</strong> {selectedPlayer.LeagueId}</Typography>
        <Typography variant="body1"><strong>Team ID:</strong> {selectedPlayer.TeamId}</Typography>
        <Typography variant="body1"><strong>Coach ID:</strong> {selectedPlayer.CoachId}</Typography>

        {/* URL Buttons */}
        {selectedPlayer.DrivingLicenseURL && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => window.open(selectedPlayer.DrivingLicenseURL, '_blank')}
            sx={{ mt: 1 }}
          >
            View Driving License
          </Button>
        )}
        {selectedPlayer.HeadshotURL && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => window.open(selectedPlayer.HeadshotURL, '_blank')}
            sx={{ mt: 1 }}
          >
            View Headshot
          </Button>
        )}
        {selectedPlayer.SignedDocumentURL && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => window.open(selectedPlayer.SignedDocumentURL, '_blank')}
            sx={{ mt: 1 }}
          >
            View Signed Document
          </Button>
        )}

        <Button onClick={handleCloseModal} sx={{ mt: 2 }} variant="contained" color="primary">
          Close
        </Button>
      </>
    )}
  </Box>
</Modal>    
</>
      )}

      {selectedTab === 1 && (
        <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Team Name</TableCell>
                <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Coach</TableCell>
                <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>City</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teams.map((team) => (
                <TableRow key={team.TeamId}>
                  <TableCell>{team.TeamName}</TableCell>
                  <TableCell>{team.Coach}</TableCell>
                  <TableCell>{team.City}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {selectedTab === 2 && (
        <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Tournament Name</TableCell>
                <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Format</TableCell>
                <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Start Date</TableCell>
                <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>End Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tournaments.map((tournament) => (
                <TableRow key={tournament.TournamentId}>
                  <TableCell>{tournament.TournamentName}</TableCell>
                  <TableCell>{tournament.Format}</TableCell>
                  <TableCell>{tournament.StartDate}</TableCell>
                  <TableCell>{tournament.EndDate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {selectedTab === 3 && (
        <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>First Name</TableCell>
                <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Last Name</TableCell>   
                <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Experience</TableCell>
                <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {referees.map((referee) => (
                <TableRow key={referee.RefereeId}>
                  <TableCell>{referee.FirstName}</TableCell>
                  <TableCell>{referee.LastName}</TableCell>
                  <TableCell>{referee.Age} years</TableCell>
                  <TableCell>{referee.Email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default PlayerAdminPage;
