import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { usePlayersData, updatePlayer } from '../services/apiService';
import { TextField, Button, Typography, Box, Grid, CircularProgress, Alert } from '@mui/material';

const EditPlayer = () => {
  const { playerId } = useParams();
  const navigate = useNavigate();
  const [player, setPlayer] = useState({
    FirstName: '',
    LastName: '',
    DOB: '',
    Email: '',
    PhoneNumber: '',
    Position: '',
    Age: '',
  });
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state

  // Fetch the player data based on playerId
  const playerData = usePlayersData(playerId);

  useEffect(() => {
    if (playerData) {
      setPlayer(playerData);
      setLoading(false);
    } else {
      setError('Player data could not be loaded');
      setLoading(false);
    }
  }, [playerData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPlayer((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async () => {
    try {
      await updatePlayer(playerId, player);
      navigate('/players'); // Redirect to players list after updating
    } catch (error) {
      setError('Failed to update player data');
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Box sx={{ width: '100%', padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Edit Player
      </Typography>
      <form onSubmit={(e) => e.preventDefault()}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              name="FirstName"
              value={player.FirstName}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              name="LastName"
              value={player.LastName}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="DOB"
              type="date"
              name="DOB"
              value={player.DOB}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              type="email"
              name="Email"
              value={player.Email}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone Number"
              type="tel"
              name="PhoneNumber"
              value={player.PhoneNumber}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Position"
              name="Position"
              value={player.Position}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Age"
              type="number"
              name="Age"
              value={player.Age}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdate}
              fullWidth
            >
              Update Player
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default EditPlayer;
